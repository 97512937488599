import { GetterTree } from 'vuex';
import { AuthState } from './state';
import { User } from '@/types/Issue';

export type Getters = {
  getUser(state: AuthState): User;
}

export const getters: GetterTree<AuthState, AuthState> & Getters = {
  getUser: (state) => state.user,
  getIsLoggedIn: (state) => Object.keys(state.user).length > 0,
};
