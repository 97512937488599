
import { useStore } from 'vuex';
import {
  inject,
  onMounted,
  onUnmounted,
  defineComponent,
} from 'vue';
import IssueDescription from '@/components/CreateIssue/IssueDescription.vue';
import IssueUsers from '@/components/CreateIssue/IssueUsers.vue';
import IssueAdmins from '@/components/CreateIssue/IssueAdmins.vue';
import useIssues from '@/composables/useIssues';
import Modal from '@/components/Modal.vue';
import useWeb3 from '@/composables/useWeb3';

export default defineComponent({
  name: 'CreateIssue',
  components: {
    Modal,
    IssueUsers,
    IssueAdmins,
    IssueDescription,
  },
  setup() {
    const store = useStore();
    const web3Service = inject('web3Service');
    const { refreshState } = useWeb3(store, web3Service);

    const {
      steps, currentStep, resetState, errors,
    } = useIssues(store);

    onMounted(async () => await refreshState());
    onUnmounted(() => resetState());

    return { errors, steps, currentStep };
  },
});
