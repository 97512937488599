import { MutationTree } from 'vuex';
import { IssuesState } from './state';
import {
  Currency,
  Option,
  User,
  Issue,
} from '@/types/Issue';

export enum IssuesMutationTypes {
  RESET_STATE = 'RESET_STATE',
  INCREMENT_STEP = 'INCREMENT_STEP',
  DECREMENT_STEP = 'DECREMENT_STEP',
  SET_CURRENCIES = 'SET_CURRENCIES',
  SET_NEW_VAULT_NAME = 'SET_NEW_VAULT_NAME',
  SET_NEW_VAULT_MATURITY_DATE = 'SET_NEW_VAULT_MATURITY_DATE',
  SET_NEW_VAULT_EXERCISE_DATE = 'SET_NEW_VAULT_EXERCISE_DATE',
  APPEND_USER_TO_VAULT = 'APPEND_USER_TO_VAULT',
  APPEND_ADMIN_TO_VAULT = 'APPEND_ADMIN_TO_VAULT',
  REMOVE_USER_FROM_VAULT = 'REMOVE_USER_FROM_VAULT',
  REMOVE_ADMIN_FROM_VAULT = 'REMOVE_ADMIN_FROM_VAULT',
  APPEND_CURRENCY_TO_VAULT = 'APPEND_CURRENCY_TO_VAULT',
  REMOVE_CURRENCY_FROM_VAULT = 'REMOVE_CURRENCY_FROM_VAULT',
  SET_ERRORS = 'SET_ERRORS',
  SET_LOADING = 'SET_LOADING',
  SET_ORIGINATED_VAULTS = 'SET_ORIGINATED_VAULTS',
  SET_PARTICIPATED_VAULTS = 'SET_PARTICIPATED_VAULTS',
  SET_ADMIN_VAULTS = 'SET_ADMIN_VAULTS',
}

export type IssuesMutations<S = IssuesState> = {
  [IssuesMutationTypes.RESET_STATE](state: S): void,
  [IssuesMutationTypes.INCREMENT_STEP](state: S): void,
  [IssuesMutationTypes.DECREMENT_STEP](state: S): void,
  [IssuesMutationTypes.SET_CURRENCIES](state: S, currencies: Currency[]): void,
  [IssuesMutationTypes.SET_NEW_VAULT_NAME](state: S, vaultName: string): void,
  [IssuesMutationTypes.SET_NEW_VAULT_MATURITY_DATE](state: S, maturityDate: Date): void,
  [IssuesMutationTypes.SET_NEW_VAULT_EXERCISE_DATE](state: S, exerciseDate: Date): void,
  [IssuesMutationTypes.APPEND_USER_TO_VAULT](state: S, userEmail: string): void,
  [IssuesMutationTypes.APPEND_ADMIN_TO_VAULT](state: S, userEmail: string): void,
  [IssuesMutationTypes.REMOVE_USER_FROM_VAULT](state: S, user: User): void,
  [IssuesMutationTypes.REMOVE_ADMIN_FROM_VAULT](state: S, user: User): void,
  [IssuesMutationTypes.REMOVE_CURRENCY_FROM_VAULT](state: S, currency: Currency): void,
  [IssuesMutationTypes.REMOVE_CURRENCY_FROM_VAULT](state: S, currency: Currency): void,
  [IssuesMutationTypes.SET_ERRORS](state: S, errors: any[]): void,
  [IssuesMutationTypes.SET_LOADING](state: S, isLoading: boolean): void,
};

export const mutations: MutationTree<IssuesState> & IssuesMutations = {
  /**
   * Sets state to the initial values
   * @param state IssuesState
   */
  [IssuesMutationTypes.RESET_STATE]: (state: IssuesState) => {
    state.newIssue.name = '';
    state.newIssue.users = [];
    state.newIssue.admins = [];
    state.newIssue.currencies = [];
    state.currentStep = 0;
  },
  /**
   * Increments currentStep
   * @param state IssuesState
   * @returns number
   */
  [IssuesMutationTypes.INCREMENT_STEP]: (state: IssuesState) => state.currentStep++,
  /**
   * Decremetns current step
   * @param state IssuesState
   * @returns number
   */
  [IssuesMutationTypes.DECREMENT_STEP]: (state: IssuesState) => state.currentStep--,
  /**
   * Set's supported currencies to the state
   * @param state IssuesState
   * @param currencies Currency[]
   */
  [IssuesMutationTypes.SET_CURRENCIES]: (state: IssuesState, currencies: Currency[]) => {
    state.currencies = currencies;
  },
  /**
   * Set new issue name
   * @param state IssuesState
   * @param vaultName string
   */
  [IssuesMutationTypes.SET_NEW_VAULT_NAME]: (state: IssuesState, vaultName: string) => {
    state.newIssue.name = vaultName;
  },
  /**
   * Set new maturityDate
   * @param state IssuesState
   * @param maturityDate Date
   */
  [IssuesMutationTypes.SET_NEW_VAULT_MATURITY_DATE]: (state: IssuesState, maturityDate: Date) => {
    state.newIssue.maturityDate = maturityDate;
  },
  /**
   * Set new exerciseDate
   * @param state IssuesState
   * @param exerciseDate Date
   */
  [IssuesMutationTypes.SET_NEW_VAULT_EXERCISE_DATE]: (state: IssuesState, exerciseDate: Date) => {
    state.newIssue.exerciseDate = exerciseDate;
  },
  /**
   * Appends new user to the issue users list
   * @param state IssuesState
   * @param user User
   */
  [IssuesMutationTypes.APPEND_USER_TO_VAULT]: (state: IssuesState, userEmail: string) => {
    const options = state.newIssue.currencies.map((c: Currency) => ({
      currencyTicker: c.ticker,
      currencyAddress: c.address,
      percentage: 0,
    } as Option));
    const user: User = {
      fullName: '',
      workEmail: userEmail,
      personalEmail: '',
      walletAddress: '',
      options,
    };
    state.newIssue.users = [...state.newIssue.users, user];
  },
  /**
   * Appends new admin to the issue users list
   * @param state IssuesState
   * @param user User
   */
  [IssuesMutationTypes.APPEND_ADMIN_TO_VAULT]: (state: IssuesState, userEmail: string) => {
    const options = [] as Option[];
    const user: User = {
      fullName: '',
      workEmail: userEmail,
      personalEmail: '',
      walletAddress: '',
      options,
    };
    state.newIssue.admins = [...state.newIssue.admins, user];
  },
  /**
   * Removes user from the issue users list
   * @param state IssuesState
   * @param user User
   */
  [IssuesMutationTypes.REMOVE_USER_FROM_VAULT]: (state: IssuesState, user: User) => {
    state.newIssue.users = state.newIssue.users.filter(
      (u: User) => u.workEmail !== user.workEmail,
    );
  },
  /**
   * Removes user from the issue users list
   * @param state IssuesState
   * @param user User
   */
  [IssuesMutationTypes.REMOVE_ADMIN_FROM_VAULT]: (state: IssuesState, user: User) => {
    state.newIssue.admins = state.newIssue.admins.filter(
      (u: User) => u.workEmail !== user.workEmail,
    );
  },
  /**
   * Appends new currency to the issue currencies list
   * @param state IssuesState
   * @param currency Currency
   */
  [IssuesMutationTypes.APPEND_CURRENCY_TO_VAULT]: (state: IssuesState, currency: Currency) => {
    state.newIssue.currencies = [...state.newIssue.currencies, currency];
  },
  /**
   * Remove currency from the issue currencies list
   * @param state IssuesState
   * @param currency Currency
   */
  [IssuesMutationTypes.REMOVE_CURRENCY_FROM_VAULT]: (state: IssuesState, currency: Currency) => {
    state.newIssue.currencies = state.newIssue.currencies.filter(
      (c: Currency) => c.address !== currency.address,
    );
  },
  /**
   * Sets errors
   * @param state IssuesState
   * @param errors Array of errors
   */
  [IssuesMutationTypes.SET_ERRORS]: (state: IssuesState, errors: any[]) => {
    state.errors = errors;
  },
  /**
   * Sets errors
   * @param state IssuesState
   * @param errors Array of errors
   */
  [IssuesMutationTypes.SET_LOADING]: (state: IssuesState, isLoading = true) => {
    state.isLoading = isLoading;
  },
  /**
   * Sets originated issues
   * @param state IssuesState
   * @param issues Array of issues
   */
  [IssuesMutationTypes.SET_ORIGINATED_VAULTS]: (state: IssuesState, issues: Issue[]) => {
    state.originatedIssues = issues;
  },
  /**
   * Sets participated issues
   * @param state IssuesState
   * @param issues Array of issues
   */
  [IssuesMutationTypes.SET_PARTICIPATED_VAULTS]: (state: IssuesState, issues: Issue[]) => {
    state.participatedIssues = issues;
  },
  /**
   * Sets admins issues
   * @param state IssuesState
   * @param issues Array of issues
   */
  [IssuesMutationTypes.SET_ADMIN_VAULTS]: (state: IssuesState, issues: Issue[]) => {
    state.adminIssues = issues;
  },
};
