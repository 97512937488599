import { ActionContext, ActionTree } from 'vuex';
import { AuthMutationTypes } from './mutations';
import { AuthState } from './state';
import { User } from '@/types/Issue';
import Api from '@/services/api';

export enum ActionTypes {
  SET_USER = 'SET_USER',
  FETCH_ME = 'FETCH_ME',
}

export interface Actions {
    [ActionTypes.SET_USER](
      context: ActionContext<AuthState, AuthState>,
      user: User,
    ): void;
    [ActionTypes.FETCH_ME](
      context: ActionContext<AuthState, AuthState>,
      api: Api,
    ): void;
}
export const actions: ActionTree<AuthState, AuthState> & Actions = {
  /**
   * Increments step on create issue form
   * @param context ActionContext
   */
  [ActionTypes.SET_USER](context: ActionContext<AuthState, AuthState>, user: User) {
    context.commit(AuthMutationTypes.SET_USER, user);
  },
  /**
   * Reads jwt
   * @param context ActionContext
   */
  async [ActionTypes.FETCH_ME](context: ActionContext<AuthState, AuthState>, api: Api) {
    const { user }: any = await api.fetchMe();
    context.commit(AuthMutationTypes.SET_USER, user);
    return true;
  },
};
