import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import CreateIssue from '../views/CreateIssue.vue';
import ProfilePage from '../views/ProfilePage.vue';
import Participate from '../views/Participate.vue';
import MyIssues from '../views/MyIssues.vue';
import useAuth from '../composables/useAuth';
import store from '../store';
import useWeb3 from '@/composables/useWeb3';
import Web3Service from '@/services/web3';

const web3Service = new Web3Service();

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/create-issue',
    name: 'CreateIssue',
    component: CreateIssue,
    beforeEnter: async (to, from, next) => {
      const { fetchMe } = useAuth(store, web3Service);
      const { disconnect } = useWeb3(store, web3Service);
      const loggedIn = await fetchMe();

      if (!loggedIn) {
        await disconnect();
        return next('/');
      }
      return next();
    },
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage,
    beforeEnter: async (to, from, next) => {
      const { fetchMe } = useAuth(store, web3Service);
      const { disconnect } = useWeb3(store, web3Service);
      const loggedIn = await fetchMe();
      if (!loggedIn) {
        await disconnect();
        return next('/');
      }
      return next();
    },
  },
  {
    path: '/my-issues',
    name: 'MyIssues',
    component: MyIssues,
    beforeEnter: async (to, from, next) => {
      const { fetchMe } = useAuth(store, web3Service);
      const { disconnect } = useWeb3(store, web3Service);
      const loggedIn = await fetchMe();
      if (!loggedIn) {
        await disconnect();
        return next('/');
      }
      return next();
    },
  },
  {
    path: '/participate/:token',
    name: 'Participate',
    component: Participate,
    beforeEnter: async (to, from, next) => {
      const { fetchMe, signUpOrLogin } = useAuth(store, web3Service);
      const { connect, isConnected } = useWeb3(store, web3Service);
      const loggedIn = await fetchMe();

      if (!isConnected.value) {
        await connect();
      }
      if (!loggedIn) {
        await signUpOrLogin();
      }
      return next();
    },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
