
import { useStore } from 'vuex';
import {
  ref,
  Ref,
  reactive,
  defineComponent,
  onMounted,
  inject,
} from 'vue';
import useWeb3 from '@/composables/useWeb3';
import Modal from '@/components/Modal.vue';
import { Currency, Issue } from '@/types/Issue';
import useIssues from '@/composables/useIssues';
import RemainingTime from '@/components/RemainingTime.vue';
import ClaimTokensForm from '@/components/MyIssues/ClaimTokensForm.vue';
import { isUnlocked } from '@/utils/countdown';

export default defineComponent({
  components: {
    Modal,
    RemainingTime,
    ClaimTokensForm,
  },
  setup() {
    const { VUE_APP_STABLE_COIN_NAME: STABLE_COIN_NAME } = process.env;
    const store = useStore();
    const web3service = inject('web3Service');

    const {
      address,
      isClaimed,
      calculatePrice,
      getEligibleAmount,
      listenOnIssueEvents,
      getEligibleAmountAtTimestamp,
    } = useWeb3(store, web3service);

    const {
      participatedIssues: issues,
      fetchParticipatedIssues,
      isUserParticipant,
    } = useIssues(store);

    const shouldShowOptionsModal: Ref<boolean> = ref(false);
    const showEligibleAmountsModal: Ref<boolean> = ref(false);

    const selectedIssue: Ref<Issue> | Ref<any> = ref(null);

    const amounts: { [key: string]: number } = reactive({});
    const amountsUntilNow: { [key: string]: number } = reactive({});
    const prices: { [key: string]: number } = reactive({});

    const showSelectedModalAmounts = (issue: Issue) => {
      showEligibleAmountsModal.value = true;
      selectedIssue.value = issue;

      issue.currencies.forEach(async (currency: Currency) => {
        const claimed = await isClaimed(issue.address, address.value, currency.address);
        amounts[currency.address] = claimed
          ? 0 : await getEligibleAmount(issue.address, currency.address, address.value);
        prices[currency.address] = claimed ? 0 : await calculatePrice(issue, currency.address, address.value);

        if (!claimed) {
          amountsUntilNow[currency.address] = await getEligibleAmountAtTimestamp(
            issue.address,
            currency.address,
            address.value,
          );
        }

        const unlocked = isUnlocked(issue.maturityDate);

        if (unlocked) {
          amountsUntilNow[currency.address] = amounts[currency.address];
        }
      });
    };

    const showOptionsModal = (issue: Issue) => {
      selectedIssue.value = issue;
      shouldShowOptionsModal.value = true;
    };

    onMounted(async () => {
      await fetchParticipatedIssues();
      issues.value.forEach((issue: Issue) => {
        if (issue.address) listenOnIssueEvents(issue.address);
      });
    });

    return {
      issues,
      amounts,
      prices,
      address,
      isClaimed,
      isUnlocked,
      selectedIssue,
      amountsUntilNow,
      showOptionsModal,
      shouldShowOptionsModal,
      isUserParticipant,
      showEligibleAmountsModal,
      showSelectedModalAmounts,
      STABLE_COIN_NAME,
    };
  },
});
