
import { useStore } from 'vuex';
import { defineComponent, inject, onMounted } from 'vue';
import NavBar from '@/components/NavBar.vue';
import useWeb3 from './composables/useWeb3';
import useAuth from './composables/useAuth';

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
  },
  setup() {
    const store = useStore();
    const web3service: any = inject('web3Service');
    const { logout } = useAuth(store, web3service);

    const { connect } = useWeb3(store, web3service);

    onMounted(async () => {
      await connect();
      await web3service.onAccountChanged(async () => await logout());
      await web3service.onChainChanged(async () => location.reload());
    });
  },
});
