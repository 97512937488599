import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './index.css';
import Web3Service from '@/services/web3';
import Api from '@/services/api';

const web3Service = new Web3Service();
const api = new Api();

createApp(App)
  .use(store)
  .use(router)
  .provide('web3Service', web3Service)
  .provide('api', api)
  .mount('#app');
