
import { useStore } from 'vuex';
import {
  ref,
  onMounted,
  defineComponent,
  watch,
} from 'vue';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import Datepicker from 'vue3-datepicker';
import { PlusIcon, ArrowNarrowRightIcon, XIcon } from '@heroicons/vue/outline';
import useIssues from '@/composables/useIssues';

export default defineComponent({
  name: 'IssueDescription',
  components: {
    XIcon,
    PlusIcon,
    Datepicker,
    ArrowNarrowRightIcon,
  },
  setup() {
    const store = useStore();
    const {
      newIssue,
      allCurrencies,
      incrementStep,
      fetchCurrencies,
      appendCurrency,
      removeCurrency,
      updateNewIssueName,
      updateNewIssueMaturityDate,
      updateNewIssueExerciseDate,
    } = useIssues(store);

    const { errorMessage, value: vaultName, meta } = useField('vaultName', yup.string().required().trim());

    const lowerLimit = ref(new Date());
    const maturityDate = ref(new Date());
    const exerciseDate = ref(new Date());

    const onNameChanged = () => {
      updateNewIssueName(vaultName.value);
    };

    const next = () => {
      if (meta.valid && newIssue.value.currencies.length > 0) incrementStep();
    };

    watch(vaultName, (newName, _) => {
      updateNewIssueName(newName);
    });

    watch(maturityDate, (newDate, _) => {
      updateNewIssueMaturityDate(newDate);
    });

    watch(exerciseDate, (newDate, _) => {
      updateNewIssueExerciseDate(newDate);
    });

    const selectedCurrency = ref(-1);

    onMounted(() => {
      vaultName.value = newIssue.name;
      fetchCurrencies();
    });

    return {
      next,
      newIssue,
      vaultName,
      lowerLimit,
      maturityDate,
      exerciseDate,
      errorMessage,
      onNameChanged,
      incrementStep,
      allCurrencies,
      appendCurrency,
      removeCurrency,
      selectedCurrency,
    };
  },
});
