
import { useStore } from 'vuex';
import {
  Ref,
  ref,
  onMounted,
  defineComponent,
  inject,
} from 'vue';
import useWeb3 from '@/composables/useWeb3';
import { Currency } from '@/types/Issue';

export default defineComponent({
  name: 'IssueStatus',
  props: ['issue'],
  setup(props) {
    const store = useStore();
    const web3service = inject('web3Service');

    const { balanceOf } = useWeb3(store, web3service);

    type Balance = {
      ticker: string;
      amount: string;
    };

    const balances: Ref<Balance[]> = ref([]);

    onMounted(async () => {
      props.issue.currencies.forEach(async (currency: Currency) => {
        const balance = await balanceOf(props.issue.address, currency.address);
        balances.value.push({
          ticker: currency.ticker,
          amount: balance,
        });
      });
    });

    return {
      props,
      balances,
    };
  },
});
