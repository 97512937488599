import { MutationTree } from 'vuex';
import { WalletState } from './state';

export enum WalletMutationTypes {
  SET_ADDRESS = 'SET_ADDRESS',
  SET_BALANCE = 'SET_BALANCE',
  SET_IS_CONNECTED = 'SET_IS_CONNECTED',
}

export type WalletMutations<S = WalletState> = {
  [WalletMutationTypes.SET_ADDRESS](state: S, address: string): void,
  [WalletMutationTypes.SET_BALANCE](state: S, balance: number): void,
  [WalletMutationTypes.SET_IS_CONNECTED](state: S, isConnected: boolean): void,

};

export const mutations: MutationTree<WalletState> & WalletMutations = {
  /**
   * Sets wallet address
   * @param state WalletState
   * @param address string
   */
  [WalletMutationTypes.SET_ADDRESS]: (state: WalletState, address: string) => {
    state.address = address;
  },
  /**
   * Sets wallet balance
   * @param state WalletState
   * @param balance number
   */
  [WalletMutationTypes.SET_BALANCE]: (state: WalletState, balance: number) => {
    state.balance = balance;
  },
  /**
   * Sets wallet balance
   * @param state WalletState
   * @param balance number
   */
  [WalletMutationTypes.SET_IS_CONNECTED]: (state: WalletState, isConnected: boolean) => {
    state.isConnected = isConnected;
  },
};
