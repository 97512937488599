
import {
  XIcon,
  PlusIcon,
  SaveIcon,
  ArrowNarrowLeftIcon,
} from '@heroicons/vue/outline';
import * as yup from 'yup';
import { useStore } from 'vuex';
import { useField } from 'vee-validate';
import { computed, defineComponent, ref } from 'vue';
import useIssues from '@/composables/useIssues';

export default defineComponent({
  name: 'IssueAdmins',
  components: {
    XIcon,
    PlusIcon,
    SaveIcon,
    ArrowNarrowLeftIcon,
  },
  setup() {
    const store = useStore();
    const {
      newIssue,
      appendAdmin: appendA,
      removeAdmin,
      createIssue,
      decrementStep,
    } = useIssues(store);

    const { errorMessage, value: adminEmail, meta } = useField(
      'email',
      yup.string().required().email().trim(),
    );

    const withoutAdmins = ref(false);

    const numberOfAdmins = computed(() => newIssue.value.admins.length);

    const invalidAdmins = ref(true);

    const appendAdmin = (user: string) => {
      if (withoutAdmins.value) return;
      if (!meta.valid) return;
      appendA(user);
      adminEmail.value = '';
      invalidAdmins.value = (numberOfAdmins.value === 0) || (numberOfAdmins.value % 2 !== 0);
    };

    return {
      newIssue,
      adminEmail,
      appendAdmin,
      removeAdmin,
      createIssue,
      errorMessage,
      decrementStep,
      invalidAdmins,
      withoutAdmins,
      numberOfAdmins,
    };
  },
});
/* eslint-disable max-len */
