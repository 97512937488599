import { GetterTree } from 'vuex';
import { IssuesState } from './state';
import { Currency, Issue } from '@/types/Issue';

export type Getters = {
  currentStep(state: IssuesState): number;
  getSteps(state: IssuesState): string[];
  getCurrencies(state: IssuesState): Currency[];
  getNewIssue(state: IssuesState): Issue;
  getName(state: IssuesState): string;
  getErrors(state: IssuesState): any[];
  getOriginatedIssues(state: IssuesState): Issue[];
  getParticipatedIssues(state: IssuesState): Issue[];
  getAdminIssues(state: IssuesState): Issue[];
}

export const getters: GetterTree<IssuesState, IssuesState> & Getters = {
  currentStep: (state) => state.currentStep,
  getSteps: (state) => state.steps,
  getCurrencies: (state) => state.currencies,
  getNewIssue: (state) => state.newIssue,
  getName: (state) => state.newIssue.name,
  getErrors: (state) => state.errors,
  getOriginatedIssues: (state) => state.originatedIssues,
  getParticipatedIssues: (state) => state.participatedIssues,
  getAdminIssues: (state) => state.adminIssues,
};
