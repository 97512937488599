
import { useStore } from 'vuex';
import {
  inject,
  reactive,
  onMounted,
  defineComponent,
  ref,
} from 'vue';
import useWeb3 from '@/composables/useWeb3';
import { User } from '@/types/Issue';
import useIssues from '@/composables/useIssues';
import { isUnlocked } from '@/utils/countdown';

export default defineComponent({
  props: ['issue'],
  emits: ['error'],
  setup(props) {
    const store = useStore();
    const web3service = inject('web3Service');

    const { isRemovedFromIssue, reAdd: reAddUser, removeAsAdmin } = useWeb3(store, web3service);
    const { createRemovalProposal, fetchUserByAddress, fetchParticipants } = useIssues(store);

    const removedParticipans: { [key: string]: boolean } = reactive({});
    const participants = ref([]);

    onMounted(async () => {
      participants.value = await fetchParticipants(props.issue._id);
      props.issue.users.forEach(async (participant: User): Promise<void> => {
        if (!participant.walletAddress) {
          removedParticipans[participant.workEmail] = false;
          return;
        }
        const removed = await isRemovedFromIssue(props.issue.address, participant.walletAddress);
        removedParticipans[participant.workEmail] = removed;
      });
    });

    const reAdd = async (userAddress: string) => {
      await reAddUser(props.issue.address, userAddress);
      location.reload();
    };

    const remove = async (userAddress: string, issueId: string) => {
      if (props.issue.admins.length > 1) {
        const user = await fetchUserByAddress(userAddress);
        await createRemovalProposal(user._id, issueId);
      } else {
        // console.log('remove as admin');
        // console.log(props.issue.address);
        await removeAsAdmin(userAddress, props.issue.address);
      }
      participants.value = await fetchParticipants(props.issue._id);
    };

    return {
      props,
      reAdd,
      remove,
      isUnlocked,
      participants,
      removedParticipans,
    };
  },
});
