
import { useStore } from 'vuex';
import {
  ref,
  Ref,
  reactive,
  defineComponent,
  onMounted,
  inject,
} from 'vue';
import { Issue } from '@/types/Issue';
import Modal from '@/components/Modal.vue';
import useWeb3 from '@/composables/useWeb3';
import useIssues from '@/composables/useIssues';
import ProposalsTable from '@/components/MyIssues/ProposalsTable.vue';

export default defineComponent({
  components: {
    Modal,
    ProposalsTable,
  },
  setup() {
    const store = useStore();

    const {
      fetchAdminIssues,
      adminIssues: issues,
    } = useIssues(store);

    const showProposalsModal: Ref<boolean> = ref(false);
    const selectedIssue: Ref<Issue> | Ref<any> = ref(null);

    const amounts: { [key: string]: number } = reactive({});

    const showProposals = async (issue: Issue) => {
      selectedIssue.value = issue;
      showProposalsModal.value = true;
    };

    onMounted(async () => {
      await fetchAdminIssues();
    });

    return {
      issues,
      amounts,
      selectedIssue,
      showProposals,
      showProposalsModal,
    };
  },
});
