
import { useStore } from 'vuex';
import {
  ref,
  reactive,
  defineComponent,
  onMounted,
  Ref,
  inject,
} from 'vue';
import { ArrowNarrowRightIcon } from '@heroicons/vue/outline';
import Api from '@/services/api';
import useWeb3 from '@/composables/useWeb3';
import { Currency } from '@/types/Issue';

const steps = {
  DEPLOY: 1,
  ADD_PARTICIPANTS: 2,
  DEPOSIT_FUNDS: 3,
};

export default defineComponent({
  props: ['issue'],
  emits: ['error'],
  components: {
    ArrowNarrowRightIcon,
  },
  setup(props, context) {
    const api = new Api();
    const step = ref(1);
    const isLoading = ref(false);

    const amounts: { [key: string]: number } = reactive({});
    const address: Ref<string> = ref('');

    const store = useStore();
    const web3service = inject('web3Service');

    const {
      deployIssue,
      depositFunds,
      hasParticipants,
      addParticipants: add,
    } = useWeb3(store, web3service);

    const deploy = async () => {
      try {
        isLoading.value = true;
        await deployIssue(props.issue);
        isLoading.value = false;
        step.value++;
      } catch (error) {
        console.log(error);
        context.emit('error');
      }
    };

    const addParticipants = async () => {
      try {
        isLoading.value = true;
        address.value = await api.fetchAddress(props.issue._id);
        await add(props.issue, address.value);
        isLoading.value = false;
        step.value++;
      } catch (error) {
        console.log(error);
        context.emit('error');
      }
    };

    const deposit = async () => {
      try {
        isLoading.value = true;
        await depositFunds(amounts, address.value);
        isLoading.value = false;
      } catch (error) {
        console.log(error);
        context.emit('error');
      }
    };

    onMounted(async () => {
      props.issue.currencies.forEach((currency: Currency) => {
        amounts[currency.address] = 0;
      });

      if (!props.issue.address) return;

      if (props.issue.address) {
        address.value = props.issue.address;
        step.value = steps.ADD_PARTICIPANTS;
      }
      const hasPs = await hasParticipants(props.issue.address);
      if (hasPs) {
        step.value = steps.DEPOSIT_FUNDS;
      }
    });

    return {
      step,
      deploy,
      deposit,
      amounts,
      isLoading,
      addParticipants,
    };
  },
});
