import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import { User } from '@/types/Issue';
import useWeb3 from './useWeb3';
import Store from '@/store';
import { ActionTypes } from '@/store/modules/auth/actions';
import Api from '@/services/api';

const useAuth = (store: typeof Store, web3service: any, router = useRouter()): Record<string, any> => {
  const {
    address,
    disconnect,
    singMessage,
    checkNetwork,
  } = useWeb3(store, web3service);

  const api = new Api();

  const user = computed(() => store.getters['Auth/getUser']);

  const isLoggedIn = computed(() => store.getters['Auth/getIsLoggedIn']);

  const getNonce = async (): Promise<string> => {
    try {
      const { nonce }: any = await api.getNonce(address.value);
      return nonce;
    } catch (error) {
      return '';
    }
  };

  const verifyNonce = async (nonce: string): Promise<void> => {
    try {
      const signature = await singMessage(nonce);
      const user: User = await api.verifyNonce(signature, address.value);
      store.dispatch(`Auth/${ActionTypes.SET_USER}`, user);
    } catch (e) {
      console.error(e);
      await disconnect();
      throw e;
    }
  };

  const signUp = async (address: string): Promise<User> => {
    const _user = await api.signUp(address);
    return _user;
  };

  const signUpOrLogin = async () => {
    await checkNetwork();
    const nonce = await getNonce();
    if (nonce) return verifyNonce(nonce);
    const user = await signUp(address.value);
    return verifyNonce(user.nonce ? user.nonce : '');
  };

  const logout = async () => {
    await disconnect();
    await api.logout();
    router.push('/');
    location.reload();
  };

  const fetchMe = async (): Promise<boolean> => {
    try {
      return await store.dispatch(`Auth/${ActionTypes.FETCH_ME}`, api);
    } catch (error) {
      return false;
    }
  };

  const updateProfile = async (user: User) => {
    try {
      const data = await api.updateProfle(user);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your profile has been updated',
        showConfirmButton: false,
        timer: 1500,
      });
      return data;
    } catch (error: any) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error?.response?.data?.message || 'Something went wrong',
        showConfirmButton: false,
        timer: 1500,
      });
      return false;
    }
  };

  onMounted(async () => {
    await fetchMe();
  });

  return {
    user,
    logout,
    fetchMe,
    isLoggedIn,
    updateProfile,
    signUpOrLogin,
  };
};

export default useAuth;
