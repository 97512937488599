import { MutationTree } from 'vuex';
import { User } from '@/types/Issue';
import { AuthState } from './state';

export enum AuthMutationTypes {
  SET_USER = 'SET_USER',
}

export type AuthMutations<S = AuthState> = {
  [AuthMutationTypes.SET_USER](state: S, user: User): void,
};

export const mutations: MutationTree<AuthState> & AuthMutations = {
  /**
   * Set logged in user
   * @param state AuthState
   * @param user User
   */
  [AuthMutationTypes.SET_USER]: (state: AuthState, user: User) => {
    state.user = user;
  },
};
