
import Swal from 'sweetalert2';
import { useStore } from 'vuex';
import {
  ref,
  defineComponent,
  Ref,
  onMounted,
  inject,
} from 'vue';
import { User, Issue } from '@/types/Issue';
import useIssues from '@/composables/useIssues';
import Modal from '@/components/Modal.vue';
import DeployIssue from '@/components/MyIssues/DeployIssue.vue';
import useWeb3 from '@/composables/useWeb3';
import ParticipantsList from '@/components/MyIssues/ParticipantsList.vue';
import IssueStats from './IssueStats.vue';
import IssueAdminsManagement from './IssueAdminsManagement.vue';

export default defineComponent({
  components: {
    Modal,
    IssueStats,
    DeployIssue,
    ParticipantsList,
    IssueAdminsManagement,
  },
  setup() {
    const store = useStore();
    const {
      originatedIssues: issues,
      fetchOriginatedIssues,
    } = useIssues(store);

    const web3service = inject('web3Service');

    const { withdraw } = useWeb3(store, web3service);

    const selectedIssue: Ref<Issue> | Ref<any> = ref(null);

    const { countAccepted } = useIssues(store);

    const showDeployModal = ref(false);
    const showParticipantsModal = ref(false);
    const showIssueStatsModal = ref(false);
    const showAdminsModal = ref(false);

    const selectIssue = (issue: Issue) => {
      selectedIssue.value = issue;
      showDeployModal.value = true;
    };

    const selectParticipants = (issue: Issue) => {
      selectedIssue.value = issue;
      showParticipantsModal.value = true;
    };

    const showIssueStats = (issue: Issue) => {
      selectedIssue.value = issue;
      showIssueStatsModal.value = true;
    };

    const selectIssueAdmins = (issue: Issue) => {
      selectedIssue.value = issue;
      showAdminsModal.value = true;
    };

    const showModal = (users: User[]) => {
      const html = users
        .map(
          (user: User) => `<div>${user.workEmail} - ${
            user.walletAddress ? 'accepted' : 'pending'
          }</div>`,
        )
        .join();

      Swal.fire({
        title: '<strong>Invitations</strong>',
        html,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok',
      });
    };

    onMounted(async () => {
      await fetchOriginatedIssues();
    });

    const reloadIssue = async () => {
      await fetchOriginatedIssues();
    };

    return {
      issues,
      withdraw,
      showModal,
      selectIssue,
      reloadIssue,
      selectedIssue,
      countAccepted,
      showIssueStats,
      showAdminsModal,
      showDeployModal,
      selectIssueAdmins,
      selectParticipants,
      showIssueStatsModal,
      showParticipantsModal,
    };
  },
});
