
import {
  XIcon,
  PlusIcon,
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/vue/outline';
import * as yup from 'yup';
import { useStore } from 'vuex';
import { useField } from 'vee-validate';
import {
  watch,
  reactive,
  defineComponent,
  onMounted,
} from 'vue';
import Swal from 'sweetalert2';
import useIssues from '@/composables/useIssues';
import { Currency, Option, User } from '@/types/Issue';

export default defineComponent({
  name: 'IssueUsers',
  components: {
    XIcon,
    PlusIcon,
    ArrowNarrowRightIcon,
    ArrowNarrowLeftIcon,
  },
  setup() {
    const store = useStore();
    const {
      newIssue,
      appendUser: appendU,
      removeUser,
      decrementStep,
      incrementStep,
    } = useIssues(store);

    const { errorMessage, value, meta } = useField(
      'email',
      yup.string().required().email().trim(),
    );

    const appendUser = (user: string) => {
      if (!meta.valid) return;
      appendU(user);
      value.value = '';
    };

    const sumPercentage = (
      users: User[],
      currency: Currency,
    ): number => users.map((user: User) => user.options.filter(
      (option: Option) => option.currencyAddress === currency.address,
    )).map((option) => option[0].percentage).reduce((total: number, num: number): number => total + num, 0);

    const currencySums: { [key: string]: { [key: string]: boolean | number } } = reactive({});

    const calculateCurrencySums = (issue: any) => {
      issue.currencies.forEach((cur: Currency) => {
        const sum = sumPercentage(issue.users, cur);
        currencySums[cur.address] = {
          sum,
          isValid: sum <= 100 && sum >= 0,
        };
      });
    };

    watch(newIssue.value, (issue, _) => {
      calculateCurrencySums(issue);
    });

    onMounted(() => {
      calculateCurrencySums(newIssue.value);
    });

    const next = () => {
      let i = 0;
      let areCurrencySumsValid = true;
      newIssue.value.currencies.forEach((cur: Currency) => {
        i++;
        const isLast = i === newIssue.value.currencies.length;
        const sum = sumPercentage(newIssue.value.users, cur);
        areCurrencySumsValid = areCurrencySumsValid && (sum <= 100 && sum >= 0);

        if (isLast) {
          if (!areCurrencySumsValid) {
            return Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Please check sums.',
            });
          }
          return incrementStep();
        }
        return '';
      });
    };

    return {
      value,
      next,
      newIssue,
      appendUser,
      removeUser,
      currencySums,
      errorMessage,
      decrementStep,
      incrementStep,
    };
  },
});
