import Swal from 'sweetalert2';
import { ActionContext, ActionTree } from 'vuex';
import { IssuesMutationTypes } from './mutations';
import { Currency, User } from '@/types/Issue';
import { IssuesState } from './state';
import Api from '@/services/api';

export enum ActionTypes {
  RESET_STATE = 'RESET_STATE',
  INCREMENT_STEP = 'INCREMENT_STEP',
  DECREMENT_STEP = 'DECREMENT_STEP',
  FETCH_CURRENCIES = 'FETCH_CURRENCIES',
  SET_NEW_VAULT_NAME = 'SET_NEW_VAULT_NAME',
  SET_NEW_VAULT_MATURITY_DATE = 'SET_NEW_VAULT_MATURITY_DATE',
  SET_NEW_VAULT_EXERCISE_DATE = 'SET_NEW_VAULT_EXERCISE_DATE',
  APPEND_USER_TO_VAULT = 'APPEND_USER_TO_VAULT',
  APPEND_ADMIN_TO_VAULT = 'APPEND_ADMIN_TO_VAULT',
  REMOVE_USER_FROM_VAULT = 'REMOVE_USER_FROM_VAULT',
  REMOVE_ADMIN_FROM_VAULT = 'REMOVE_ADMIN_FROM_VAULT',
  APPEND_CURRENCY_TO_VAULT = 'APPEND_CURRENCY_TO_VAULT',
  REMOVE_CURRENCY_FROM_VAULT = 'REMOVE_CURRENCY_FROM_VAULT',
  POST_NEW_VAULT = 'POST_NEW_VAULT',
  FETCH_ORIGINATED_VAULTS = 'FETCH_ORIGINATED_VAULTS',
  FETCH_PARTICIPATED_VAULTS = 'FETCH_PARTICIPATED_VAULTS',
  FETCH_ADMIN_VAULTS = 'FETCH_ADMIN_VAULTS',
}

export interface Actions {
  [ActionTypes.RESET_STATE](
    context: ActionContext<IssuesState, IssuesState>
  ): void;
  [ActionTypes.INCREMENT_STEP](
    context: ActionContext<IssuesState, IssuesState>
  ): void;
  [ActionTypes.DECREMENT_STEP](
    context: ActionContext<IssuesState, IssuesState>
  ): void;
  [ActionTypes.SET_NEW_VAULT_NAME](
    context: ActionContext<IssuesState, IssuesState>,
    vaultName: string,
  ): void;
  [ActionTypes.SET_NEW_VAULT_EXERCISE_DATE](
    context: ActionContext<IssuesState, IssuesState>,
    exerciseDate: Date,
  ): void;
  [ActionTypes.SET_NEW_VAULT_MATURITY_DATE](
    context: ActionContext<IssuesState, IssuesState>,
    maturityDate: Date,
  ): void;
  [ActionTypes.APPEND_USER_TO_VAULT](
    context: ActionContext<IssuesState, IssuesState>,
    userEmail: string,
  ): void;
  [ActionTypes.APPEND_ADMIN_TO_VAULT](
    context: ActionContext<IssuesState, IssuesState>,
    userEmail: string,
  ): void;
  [ActionTypes.REMOVE_USER_FROM_VAULT](
    context: ActionContext<IssuesState, IssuesState>,
    user: User,
  ): void;
  [ActionTypes.REMOVE_ADMIN_FROM_VAULT](
    context: ActionContext<IssuesState, IssuesState>,
    user: User,
  ): void;
  [ActionTypes.APPEND_CURRENCY_TO_VAULT](
    context: ActionContext<IssuesState, IssuesState>,
    currency: Currency,
  ): void;
  [ActionTypes.REMOVE_CURRENCY_FROM_VAULT](
    context: ActionContext<IssuesState, IssuesState>,
    currency: Currency,
  ): void;
  [ActionTypes.POST_NEW_VAULT](
    context: ActionContext<IssuesState, IssuesState>,
    api: Api,
  ): void;
  [ActionTypes.FETCH_ORIGINATED_VAULTS](
    context: ActionContext<IssuesState, IssuesState>,
    payload: { api: Api, _id :string },
  ): void;
  [ActionTypes.FETCH_PARTICIPATED_VAULTS](
    context: ActionContext<IssuesState, IssuesState>,
    payload: { api: Api, workEmail :string },
  ): void;
  [ActionTypes.FETCH_ADMIN_VAULTS](
    context: ActionContext<IssuesState, IssuesState>,
    payload: { api: Api, adminAddress :string },
  ): void;
}

export const actions: ActionTree<IssuesState, IssuesState> & Actions = {
  /**
   * Increments step on create issue form
   * @param context ActionContext
   */
  [ActionTypes.INCREMENT_STEP](context: ActionContext<IssuesState, IssuesState>) {
    context.commit(IssuesMutationTypes.INCREMENT_STEP);
  },
  /**
   * Decrements step on create issue form
   * @param context ActionContext
   */
  [ActionTypes.DECREMENT_STEP](context: ActionContext<IssuesState, IssuesState>) {
    context.commit(IssuesMutationTypes.DECREMENT_STEP);
  },
  /**
   * Fetchs all supported currencies
   * @param context ActionContext
   */
  [ActionTypes.FETCH_CURRENCIES](context: ActionContext<IssuesState, IssuesState>) {
    const currencies: Currency[] = [{
      name: 'Uniswap',
      ticker: 'UNI',
      address: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
      optionPrice: 1.00,
    }, {
      name: 'DAI',
      ticker: 'DAI',
      address: '0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa',
      optionPrice: 10.00,
    }];
    context.commit(IssuesMutationTypes.SET_CURRENCIES, currencies);
  },
  /**
   * Set's new issue name
   * @param context ActionContext
   * @param vaultName string
   */
  [ActionTypes.SET_NEW_VAULT_NAME](
    context: ActionContext<IssuesState,
    IssuesState>, vaultName: string,
  ) {
    context.commit(IssuesMutationTypes.SET_NEW_VAULT_NAME, vaultName);
  },
  /**
   * Set's new issue locked up time
   * @param context ActionContext
   * @param userEmail string
   */
  [ActionTypes.SET_NEW_VAULT_EXERCISE_DATE](
    context: ActionContext<IssuesState, IssuesState>,
    exerciseDate: Date,
  ) {
    context.commit(IssuesMutationTypes.SET_NEW_VAULT_EXERCISE_DATE, exerciseDate);
  },
  /**
   * Set's new issue locked up time
   * @param context ActionContext
   * @param userEmail string
   */
  [ActionTypes.SET_NEW_VAULT_MATURITY_DATE](
    context: ActionContext<IssuesState, IssuesState>,
    maturityDate: Date,
  ) {
    context.commit(IssuesMutationTypes.SET_NEW_VAULT_MATURITY_DATE, maturityDate);
  },
  /**
   * Appends new user to the issue
   * @param context ActionContext
   * @param user User
   */
  [ActionTypes.APPEND_USER_TO_VAULT](
    context: ActionContext<IssuesState, IssuesState>,
    userEmail: string,
  ) {
    context.commit(IssuesMutationTypes.APPEND_USER_TO_VAULT, userEmail);
  },
  /**
   * Appends new admin to the issue
   * @param context ActionContext
   * @param user User
   */
  [ActionTypes.APPEND_ADMIN_TO_VAULT](
    context: ActionContext<IssuesState, IssuesState>,
    userEmail: string,
  ) {
    context.commit(IssuesMutationTypes.APPEND_ADMIN_TO_VAULT, userEmail);
  },
  /**
   * Removes user from the issue
   * @param context ActionContext
   * @param user User
   */
  [ActionTypes.REMOVE_USER_FROM_VAULT](
    context: ActionContext<IssuesState, IssuesState>,
    user: User,
  ) {
    context.commit(IssuesMutationTypes.REMOVE_USER_FROM_VAULT, user);
  },
  /**
   * Removes user from the issue
   * @param context ActionContext
   * @param user User
   */
  [ActionTypes.REMOVE_ADMIN_FROM_VAULT](
    context: ActionContext<IssuesState, IssuesState>,
    user: User,
  ) {
    context.commit(IssuesMutationTypes.REMOVE_ADMIN_FROM_VAULT, user);
  },
  /**
   * Appends new currency to the issue
   * @param context ActionContext
   * @param currency Currency
   */
  [ActionTypes.APPEND_CURRENCY_TO_VAULT](
    context: ActionContext<IssuesState,
    IssuesState>, currency: Currency,
  ) {
    context.commit(IssuesMutationTypes.APPEND_CURRENCY_TO_VAULT, currency);
  },
  /**
   * Removes currency from the issue
   * @param context ActionContext
   * @param currency Currency
   */
  [ActionTypes.REMOVE_CURRENCY_FROM_VAULT](
    context: ActionContext<IssuesState,
    IssuesState>, currency: Currency,
  ) {
    context.commit(IssuesMutationTypes.REMOVE_CURRENCY_FROM_VAULT, currency);
  },
  /**
   * Resets state
   * Appends new currency to the issue
   * @param context ActionContext
   */
  [ActionTypes.RESET_STATE](context: ActionContext<IssuesState, IssuesState>) {
    context.commit(IssuesMutationTypes.RESET_STATE);
  },
  async [ActionTypes.POST_NEW_VAULT](context: ActionContext<IssuesState, IssuesState>, api: Api): Promise<boolean> {
    try {
      context.commit('SET_LOADING');
      await api.createIssue(context.state.newIssue);
      context.commit('SET_LOADING', false);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your issue has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
      return true;
    } catch (e) {
      context.commit('SET_ERRORS', e);
      context.commit('SET_LOADING', false);
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Something went wrong! Please check issue data again.',
        showConfirmButton: true,
      });
      return false;
    }
  },
  /**
   * Fetch originated issues
   * Appends new currency to the issue
   * @param context ActionContext
   * @param api API
   */
  async [ActionTypes.FETCH_ORIGINATED_VAULTS](
    context: ActionContext<IssuesState, IssuesState>,
    payload: { api: Api, _id: string },
  ): Promise<boolean> {
    try {
      const { api, _id } = payload;
      context.commit(IssuesMutationTypes.SET_LOADING);
      const { issues }: any = await api.fetchOriginatedIssues(_id);
      context.commit(IssuesMutationTypes.SET_ORIGINATED_VAULTS, issues);
      context.commit(IssuesMutationTypes.SET_LOADING, false);
      return true;
    } catch (errors) {
      context.commit(IssuesMutationTypes.SET_ERRORS, errors);
      context.commit(IssuesMutationTypes.SET_LOADING, false);
      return false;
    }
  },
  async [ActionTypes.FETCH_PARTICIPATED_VAULTS](
    context: ActionContext<IssuesState, IssuesState>,
    payload: { api: Api, workEmail: string },
  ): Promise<boolean> {
    try {
      const { api, workEmail } = payload;
      context.commit(IssuesMutationTypes.SET_LOADING);
      const { issues }: any = await api.fetchParticipatedIssues(workEmail);
      context.commit(IssuesMutationTypes.SET_PARTICIPATED_VAULTS, issues);
      context.commit(IssuesMutationTypes.SET_LOADING, false);
      return true;
    } catch (errors) {
      context.commit(IssuesMutationTypes.SET_ERRORS, errors);
      context.commit(IssuesMutationTypes.SET_LOADING, false);
      return false;
    }
  },
  async [ActionTypes.FETCH_ADMIN_VAULTS](
    context: ActionContext<IssuesState, IssuesState>,
    payload: { api: Api, adminAddress: string },
  ): Promise<boolean> {
    try {
      const { api, adminAddress } = payload;
      context.commit(IssuesMutationTypes.SET_LOADING);
      const { issues }: any = await api.fetchAdminIssues(adminAddress);
      context.commit(IssuesMutationTypes.SET_ADMIN_VAULTS, issues);
      context.commit(IssuesMutationTypes.SET_LOADING, false);
      return true;
    } catch (errors) {
      context.commit(IssuesMutationTypes.SET_ERRORS, errors);
      context.commit(IssuesMutationTypes.SET_LOADING, false);
      return false;
    }
  },
};
