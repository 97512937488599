
import * as Yup from 'yup';
import { Form, Field } from 'vee-validate';
import { useStore } from 'vuex';
import {
  inject,
  onMounted,
  defineComponent,
} from 'vue';
import useAuth from '@/composables/useAuth';
import useWeb3 from '@/composables/useWeb3';

export default defineComponent({
  components: {
    VForm: Form,
    VField: Field,
  },
  setup() {
    const store = useStore();
    const web3Service = inject('web3Service');

    const { user, updateProfile, fetchMe } = useAuth(store, web3Service);
    const { address } = useWeb3(store, web3Service);

    const schema = Yup.object().shape({
      fullName: Yup.string().trim().required(),
      walletAddress: Yup.string().trim().required(),
      workEmail: Yup.string().trim().required(),
    });

    const onSubmit = async (values: any) => {
      const result = await schema.validate(values);
      await updateProfile(result);
    };

    onMounted(async () => {
      await fetchMe();
    });

    return {
      user,
      schema,
      address,
      onSubmit,
    };
  },
});
