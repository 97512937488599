<template>
  <div class="text-white flex flex-col gap-4 min-h-screen">
    <div class="row-span-3 p-6 border-2 border-white">
      <div class="row">
        <button
          @click.prevent="show('originator')"
          class="border p-2 m-2 active"
          :class="showOriginator ? 'border-green-700' : ''"
        >
          Originator
        </button>
        <button
          @click.prevent="show('participant')"
          class="border p-2 m-2"
          :class="showParticipant ? 'border-green-700' : ''"
        >
          Participant
        </button>
        <button
          @click.prevent="show('admin')"
          class="border p-2 m-2"
          :class="showAdmin ? 'border-green-700' : ''"
        >
          Admin
        </button>
      </div>
      <div class="row p-3">
        <originator-table v-if="showOriginator"/>
        <participant-table v-if="showParticipant" />
        <admins-table v-if="showAdmin" />
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { defineComponent, onMounted, ref } from 'vue';
import OriginatorTable from '@/components/MyIssues/OriginatorTable.vue';
import ParticipantTable from '@/components/MyIssues/ParticipantTable.vue';
import useIssues from '@/composables/useIssues';
import AdminsTable from '@/components/MyIssues/AdminsTable.vue';

export default defineComponent({
  components: {
    AdminsTable,
    OriginatorTable,
    ParticipantTable,
  },
  setup() {
    const showOriginator = ref(true);
    const showParticipant = ref(false);
    const showAdmin = ref(false);

    const store = useStore();
    const {
      originatedIssues,
      participatedIssues,
    } = useIssues(store);

    const route = useRoute();

    const show = (tab) => {
      switch (tab) {
        case 'originator':
          showOriginator.value = true;
          showParticipant.value = false;
          showAdmin.value = false;
          break;
        case 'participant':
          showOriginator.value = false;
          showParticipant.value = true;
          showAdmin.value = false;
          break;
        case 'admin':
          showOriginator.value = false;
          showParticipant.value = false;
          showAdmin.value = true;
          break;
        default:
          showOriginator.value = false;
          showParticipant.value = false;
          showAdmin.value = true;
          break;
      }
    };

    onMounted(() => {
      if (route.query.tab) show(route.query.tab);
    });

    return {
      show,
      showAdmin,
      showOriginator,
      showParticipant,
      originatedIssues,
      participatedIssues,
    };
  },
});
</script>
