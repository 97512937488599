
import { ref, defineComponent, onMounted } from 'vue';
import countdown from '@/utils/countdown';

export default defineComponent({
  props: ['endTime', 'message'],
  setup(props) {
    const remainingTime = ref('');
    const getRemaningTime = () => {
      const remaining = countdown(props.endTime);
      if (remaining) {
        remainingTime.value = remaining as string;
      } else {
        remainingTime.value = props.message;
      }
    };
    onMounted(() => {
      getRemaningTime();
    });
    setInterval(() => {
      getRemaningTime();
    }, 1000);
    return {
      remainingTime,
    };
  },
});
