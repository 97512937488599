export default (date: string): string | boolean => {
  const countDownDate = new Date(date).getTime();
  const now = new Date().getTime();

  const distance = countDownDate - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  if (distance < 0) return false;
  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};

export const isUnlocked = (date: string | Date): boolean => {
  let countDownDate;
  if (typeof date === 'string') {
    countDownDate = new Date(date).getTime();
  } else {
    countDownDate = date.getTime();
  }
  const now = new Date().getTime();

  const distance = countDownDate - now;
  if (distance < 0) return true;
  return false;
};
