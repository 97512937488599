
import {
  ref,
  inject,
  computed,
  watchEffect,
} from 'vue';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';
import { useStore } from 'vuex';
import { MenuIcon, XIcon } from '@heroicons/vue/outline';
import { useRoute } from 'vue-router';
import useWeb3 from '@/composables/useWeb3';
import useAuth from '@/composables/useAuth';

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    XIcon,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const web3service = inject('web3Service');

    const {
      address,
      balance,
      connect,
      isConnected,
      disconnect,
    } = useWeb3(store, web3service);

    const { signUpOrLogin, logout: clearCookie, isLoggedIn } = useAuth(store, web3service);

    const navigation = ref([
      {
        name: 'Home',
        href: '/',
        current: false,
        key: 'home',
      },
      {
        name: 'My Issues',
        href: '/my-issues?tab=originator',
        current: false,
        key: 'my-issues',
      },
      {
        name: 'Create Issue',
        href: '/create-issue',
        current: false,
        key: 'create-issue',
      },
      {
        name: 'About',
        href: '/about',
        current: false,
        key: 'about',
      },
    ]);

    const path = computed(() => route.path);

    const login = async () => {
      await connect();
      await signUpOrLogin();
    };

    const logout = async () => {
      await disconnect();
      await clearCookie();
    };

    watchEffect(() => {
      navigation.value.forEach((_, i) => {
        navigation.value[i].current = navigation.value[i].href === path.value;
      });
    });

    return {
      login,
      logout,
      address,
      balance,
      connect,
      disconnect,
      isLoggedIn,
      navigation,
      isConnected,
    };
  },
};
