import { createAlchemyWeb3 } from '@alch/alchemy-web3';
import { AbiItem } from 'web3-utils';
import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import { toBn } from 'evm-bn';
import VAULT from '@/abi/Issue.json';
import ERC20 from '@/abi/Erc20.json';
import VAULT_FACTORY from '@/abi/IssueFactory.json';
import networks from './networks.json';

const {
  VUE_APP_REMOVER,
  VUE_APP_WS_RPC: RPC,
  VUE_APP_VAULT_FACTORY,
  VUE_APP_NETWORK: NETWORK,
  VUE_APP_STABLE_COIN: STABLE_COIN,
} = process.env;

type Network = keyof typeof networks;

class Web3Service {
  protected web3Modal: Web3Modal;

  protected ethersProvider: any;

  protected networkProvider: any;

  protected vaultFactory: string;

  protected remover: string;

  protected networkParams: any;

  constructor() {
    this.web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions: {},
      theme: 'dark',
    });
    this.remover = String(VUE_APP_REMOVER);
    this.vaultFactory = String(VUE_APP_VAULT_FACTORY);
    this.connect().then().catch();
    this.networkParams = networks[NETWORK as Network || 'polygon-testnet' as Network];
  }

  public async connect(): Promise<void> {
    this.networkProvider = await this.web3Modal.connect();
    this.ethersProvider = new ethers.providers.Web3Provider(this.networkProvider);
    await this.switchToSupportedNetwork();
  }

  public async getNetwork(): Promise<ethers.providers.Network | null> {
    try {
      await this.checkProvider();
      return this.ethersProvider.getNetwork();
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  private async checkProvider(): Promise<void> {
    try {
      if (!this.web3Modal.cachedProvider) {
        this.networkProvider = await this.web3Modal.connect();
      }
    } catch (e) {
      console.log(e);
    }
  }

  public async onChainChanged(cb: any): Promise<void> {
    try {
      await this.checkProvider();
      this.networkProvider.on('chainChanged', async (info: any) => {
        await cb(info);
      });
    } catch (e) {
      console.log(e);
    }
  }

  async onAccountChanged(cb: any): Promise<void> {
    try {
      await this.checkProvider();
      console.log(this.networkProvider);
      this.networkProvider.on('accountsChanged', async () => {
        await cb();
      });
    } catch (e) {
      console.log('Accounts change error');
      console.log(e);
    }
  }

  public async getAddress(): Promise<string> {
    try {
      await this.checkProvider();
      const accounts = await this.ethersProvider.listAccounts();
      return accounts[0];
    } catch (e) {
      return '';
    }
  }

  public async isConnected(): Promise<boolean> {
    try {
      await this.checkProvider();
      const accounts = await this.ethersProvider.listAccounts();
      return accounts.length > 0;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  public async getBalance(address: string): Promise<any> {
    try {
      await this.checkProvider();
      return await this.ethersProvider.getBalance(address);
    } catch (e) {
      return null;
    }
  }

  public disconnect(): void {
    this.web3Modal.clearCachedProvider();
  }

  public async signMessage(message: string): Promise<string> {
    const signer = this.ethersProvider.getSigner();
    return await signer.signMessage(message);
  }

  public async deployIssue(
    exerciseTimestamp: number,
    maturityTimestamp: number,
    currencies: string[],
    optionPrices: number[],
    _id: string,
    hasAdmins = false,
  ): Promise<string | null> {
    try {
      await this.checkProvider();
      const prices = optionPrices.map((price) => toBn(price.toString()));
      const provider = new ethers.providers.Web3Provider(this.networkProvider);

      const contract = new ethers.Contract(
        this.vaultFactory,
        VAULT_FACTORY.abi,
        provider.getSigner(),
      );

      const tx = await contract.createIssue(
        STABLE_COIN,
        hasAdmins ? this.remover : this.getAddress(),
        ethers.BigNumber.from(maturityTimestamp.toString()),
        ethers.BigNumber.from(exerciseTimestamp.toString()),
        currencies,
        prices,
        _id,
      );
      await tx.wait();
      return tx;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  public async addParticipants(
    employees: Array<any>,
    vaultAddress: string,
  ): Promise<any> {
    try {
      await this.checkProvider();
      const provider = new ethers.providers.Web3Provider(this.networkProvider);

      const contract = new ethers.Contract(
        vaultAddress,
        VAULT.abi,
        provider.getSigner(),
      );

      const tx = await contract.addEmployees(
        employees,
      );
      await tx.wait();
      return tx;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  public async approve(
    token: string,
    spender: string,
    amount: string,
  ): Promise<any> {
    try {
      await this.checkProvider();
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        token,
        ERC20.abi,
        provider.getSigner(),
      );
      const tx = await contract.approve(spender, amount);
      await tx.wait();
      return tx;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  public async depositFunds(
    token: string,
    amount: string,
    vaultAddress: string,
  ): Promise<any> {
    try {
      await this.checkProvider();
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        vaultAddress,
        VAULT.abi,
        provider.getSigner(),
      );
      const tx = await contract.depositFunds(token, amount, {
        gasLimit: 250000,
      });
      await tx.wait();
      return tx;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  public async hasParticipants(vaultAddress: string): Promise<any> {
    try {
      await this.checkProvider();
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        vaultAddress,
        VAULT.abi,
        provider.getSigner(),
      );

      return (await contract.employessKeysCount()) > 0;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  public async getEligibleAmount(
    vaultAddress: string,
    token: string,
    participant: string,
  ): Promise<any> {
    try {
      await this.checkProvider();
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        vaultAddress,
        VAULT.abi,
        provider.getSigner(),
      );
      return await contract.getEligibleAmount(token, participant);
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  public async getEligibleAmountAtTimestamp(
    vaultAddress: string,
    token: string,
    participant: string,
    ts: number,
  ): Promise<any> {
    try {
      await this.checkProvider();
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        vaultAddress,
        VAULT.abi,
        provider.getSigner(),
      );
      return await contract.getEligibleAmountAtTimestamp(token, participant, ts);
    } catch (e) {
      return null;
    }
  }

  public async claim(
    vaultAddress: string,
    token: string,
    amount: number,
  ): Promise<any> {
    try {
      await this.checkProvider();
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        vaultAddress,
        VAULT.abi,
        provider.getSigner(),
      );
      return await contract.claim(token, ethers.utils.parseUnits(amount.toString()));
    } catch (e) {
      return null;
    }
  }

  public async remove(
    vaultAddress: string,
    user: string,
  ): Promise<any> {
    try {
      await this.checkProvider();
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        vaultAddress,
        VAULT.abi,
        provider.getSigner(),
      );
      return await contract.removeEmployee(user);
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  public async withdraw(
    vaultAddress: string,
    token: string,
  ): Promise<any> {
    try {
      await this.checkProvider();
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        vaultAddress,
        VAULT.abi,
        provider.getSigner(),
      );
      return await contract.withdraw(token);
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  public async isRemoved(vaultAddress: string, address: string): Promise<boolean> {
    try {
      await this.checkProvider();
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        vaultAddress,
        VAULT.abi,
        provider.getSigner(),
      );
      const removedAt = await contract.employeesRemovedAt(address);
      return removedAt > 0;
    } catch (e) {
      return false;
    }
  }

  public async isClaimed(vaultAddress: string, user: string, token: string): Promise<boolean> {
    try {
      await this.checkProvider();
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        vaultAddress,
        VAULT.abi,
        provider.getSigner(),
      );
      return await contract.claimed(user, token);
    } catch (e) {
      return false;
    }
  }

  public async balanceOf(address: string, currency: string): Promise<string> {
    try {
      await this.checkProvider();
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        currency,
        ERC20.abi,
        provider.getSigner(),
      );
      const balance = await contract.balanceOf(address);
      return ethers.utils.formatEther(balance).toString();
    } catch (error) {
      return '0';
    }
  }

  public static async listenOnClaim(
    contractAddress: string,
    scb: (data: any) => void,
    fcb: (data: any) => void,
  ): Promise<void> {
    const rpc = RPC || '';
    const web3js = createAlchemyWeb3(rpc);
    const contract = new web3js.eth.Contract(VAULT.abi as AbiItem[], contractAddress);
    contract.events.ClaimSuccessfull().on('data', (data: any) => {
      scb(data);
    }).on('error', (error: any) => {
      console.error(error);
    });
    contract.events.ClaimFailed().on('data', (data: any) => {
      fcb(data);
    }).on('error', (error: any) => {
      console.error(error);
    });
  }

  public async addSupportedNetwork(): Promise<any> {
    return await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [this.networkParams],
    });
  }

  public async switchToSupportedNetwork(): Promise<any> {
    try {
      return await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: this.networkParams.chainId }],
      });
    } catch (error) {
      await this.addSupportedNetwork();
      return await this.switchToSupportedNetwork();
    }
  }

  public async isValidNetwork() {
    const network = await this.getNetwork();
    return network?.chainId === this.networkParams.chainId;
  }

  public async signVote(vote: string) {
    try {
      await this.checkProvider();
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const signer = provider.getSigner();
      return await signer.signMessage(Buffer.from(vote));
    } catch (e) {
      return false;
    }
  }

  public async reAddEmployee(vaultAddress: string, address: string) {
    try {
      await this.checkProvider();
      const provider = new ethers.providers.Web3Provider(this.networkProvider);
      const contract = new ethers.Contract(
        vaultAddress,
        VAULT.abi,
        provider.getSigner(),
      );
      const removedAt = await contract.reAddEmployee(address);
      return removedAt > 0;
    } catch (e) {
      return false;
    }
  }
}

export default Web3Service;
