import { computed, inject } from 'vue';
import { useRouter } from 'vue-router';
import Store from '@/store';
import { ActionTypes } from '@/store/modules/issues/actions';
import { Currency, Issue, User } from '@/types/Issue';
import useAuth from './useAuth';
import Web3Service from '@/services/web3';

const useIssues = (store: typeof Store, router = useRouter()): any => {
  const api: any = inject('api');
  const web3Service = new Web3Service();
  const { user } = useAuth(store, web3Service);

  const newIssue = computed(() => store.getters['Issues/getNewIssue']);

  const newIssueName = computed(() => store.getters['Issues/getNewIssueName']);

  const originatedIssues = computed(() => store.getters['Issues/getOriginatedIssues']);

  const participatedIssues = computed(() => store.getters['Issues/getParticipatedIssues']);

  const adminIssues = computed(() => store.getters['Issues/getAdminIssues']);

  const steps = computed(() => store.getters['Issues/getSteps']);

  const currentStep = computed(() => store.getters['Issues/currentStep']);

  const allCurrencies = computed(() => store.getters['Issues/getCurrencies']);

  const errors = computed(() => store.getters['Issues/getErrors']);

  const incrementStep = () => store.dispatch(`Issues/${ActionTypes.INCREMENT_STEP}`);

  const decrementStep = () => store.dispatch(`Issues/${ActionTypes.DECREMENT_STEP}`);

  const fetchCurrencies = () => store.dispatch(`Issues/${ActionTypes.FETCH_CURRENCIES}`);

  const updateNewIssueName = (newIssue: Issue) => store.dispatch(`Issues/${ActionTypes.SET_NEW_VAULT_NAME}`, newIssue);

  const updateNewIssueMaturityDate = (maturityDate: Date) => store.dispatch(
    `Issues/${ActionTypes.SET_NEW_VAULT_MATURITY_DATE}`, maturityDate,
  );

  const updateNewIssueExerciseDate = (exerciseDate: Date) => store.dispatch(
    `Issues/${ActionTypes.SET_NEW_VAULT_EXERCISE_DATE}`, exerciseDate,
  );

  const appendUser = (userEmail: string) => store.dispatch(`Issues/${ActionTypes.APPEND_USER_TO_VAULT}`, userEmail);

  const appendAdmin = (userEmail: string) => store.dispatch(`Issues/${ActionTypes.APPEND_ADMIN_TO_VAULT}`, userEmail);

  const removeUser = (user: User) => store.dispatch(`Issues/${ActionTypes.REMOVE_USER_FROM_VAULT}`, user);

  const removeAdmin = (user: User) => store.dispatch(`Issues/${ActionTypes.REMOVE_ADMIN_FROM_VAULT}`, user);

  const appendCurrency = (currency: Currency) => {
    store.dispatch(`Issues/${ActionTypes.APPEND_CURRENCY_TO_VAULT}`, currency);
  };

  const removeCurrency = (currency: Currency) => {
    store.dispatch(`Issues/${ActionTypes.REMOVE_CURRENCY_FROM_VAULT}`, currency);
  };

  const resetState = () => store.dispatch(`Issues/${ActionTypes.RESET_STATE}`);

  const createIssue = async () => {
    const created = await store.dispatch(`Issues/${ActionTypes.POST_NEW_VAULT}`, api);
    if (created) router.push('/my-issues?tab=originator');
  };

  const fetchOriginatedIssues = async () => await store.dispatch(`Issues/${ActionTypes.FETCH_ORIGINATED_VAULTS}`, {
    api,
    _id: user.value._id,
  });

  const fetchParticipatedIssues = async () => await store.dispatch(`Issues/${ActionTypes.FETCH_PARTICIPATED_VAULTS}`, {
    api,
    workEmail: user.value.workEmail,
  });

  const fetchAdminIssues = async () => await store.dispatch(`Issues/${ActionTypes.FETCH_ADMIN_VAULTS}`, {
    api,
    adminAddress: user.value.walletAddress,
  });

  const fetchProposals = async (_id: string) => await api.fetchProposals(_id);

  const participate = async (token: string) => await api.participate(token);

  const countAccepted = (users: User[]) => users.reduce((total, u) => (u.walletAddress ? total + 1 : total), 0);

  const isUserParticipant = (users: User[]): boolean => {
    let participated = false;
    for (let i = 0; i < users.length; i++) {
      const u = users[i];
      if (u.workEmail === user.value.workEmail && u.walletAddress) {
        participated = true;
      }
    }
    return participated;
  };

  const createRemovalProposal = async (uId: string, vId: string, p = 'participant') => {
    return await api.createRemovalProposal(uId, vId, p);
  };

  const fetchUserByAddress = async (userAddress: string) => await api.fetchUserByAddress(userAddress);

  const fetchParticipants = async (issueId: string) => await api.fetchParticipants(issueId);

  const signVote = async (vote: string) => await web3Service.signVote(vote);

  const sendVote = async (proposalId: string, vote: string) => await api.sendVote(proposalId, vote);

  const inviteAdmin = async (email: string, issueId: string) => await api.inviteAdmin(email, issueId);

  const removeAdminApi = async (email: string, issueId: string) => await api.removeAdmin(email, issueId);

  return {
    steps,
    errors,
    signVote,
    sendVote,
    newIssue,
    appendUser,
    removeUser,
    resetState,
    adminIssues,
    inviteAdmin,
    currentStep,
    removeAdmin,
    appendAdmin,
    participate,
    createIssue,
    newIssueName,
    countAccepted,
    incrementStep,
    allCurrencies,
    decrementStep,
    removeAdminApi,
    appendCurrency,
    removeCurrency,
    fetchProposals,
    fetchCurrencies,
    originatedIssues,
    fetchAdminIssues,
    isUserParticipant,
    fetchParticipants,
    participatedIssues,
    fetchUserByAddress,
    updateNewIssueName,
    createRemovalProposal,
    fetchOriginatedIssues,
    fetchParticipatedIssues,
    updateNewIssueMaturityDate,
    updateNewIssueExerciseDate,
  };
};

export default useIssues;
