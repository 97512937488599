
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { defineComponent, onMounted } from 'vue';
import useIssues from '@/composables/useIssues';

export default defineComponent({
  name: 'Participate',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const { participate } = useIssues(store);

    onMounted(async () => {
      const { status, role } = await participate(route.params.token);
      if (status === 200) {
        if (role === 'admin') {
          router.push('/my-issues?tab=admin');
        } else {
          router.push('/my-issues?tab=participant');
        }
      }
    });
  },
});
