
import { useStore } from 'vuex';
import {
  ref,
  inject,
  reactive,
  onMounted,
  defineComponent,
} from 'vue';
import Swal from 'sweetalert2';
import { Currency, Issue } from '@/types/Issue';
import useWeb3 from '@/composables/useWeb3';

export default defineComponent({
  components: {},
  props: ['issue'],
  setup(props) {
    const store = useStore();
    const web3service = inject('web3Service');
    const {
      claim: web3Claim,
      address,
      isClaimed,
      getEligibleAmountAtTimestamp,
    } = useWeb3(store, web3service);

    const selectedAmount = ref(0);
    const selectedCurrency = ref('');
    const eligibleAmounts: { [key: string]: number } = reactive({});

    onMounted(() => {
      props.issue.currencies.forEach(async (currency: Currency) => {
        eligibleAmounts[currency.address] = await getEligibleAmountAtTimestamp(
          props.issue.address,
          currency.address,
          address.value,
        );
      });
    });

    const claim = async (issue: Issue, token: string, amount: number) => {
      const claimed = await isClaimed(issue.address, address.value, token);
      if (claimed) {
        Swal.fire({
          icon: 'error',
          title: 'You already claimed your tokens!',
          text: '',
        });
        return;
      }
      try {
        await web3Claim(issue, token, address.value, amount);
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: 'There was an error!',
          text: 'Your account is either removed from the issue or you don\'t have sufficinet amount of stable coins.',
        });
      }
    };

    const options: any[] = [];
    return {
      props,
      claim,
      options,
      address,
      selectedAmount,
      eligibleAmounts,
      selectedCurrency,
      getEligibleAmountAtTimestamp,
    };
  },
});
