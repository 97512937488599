import { Currency, User, Issue } from '@/types/Issue';

export const state = {
  newIssue: {
    name: '',
    currencies: [] as Currency[],
    users: [] as User[],
    admins: [] as User[],
    maturityDate: new Date(),
    exerciseDate: new Date(),
  } as Issue,
  steps: ['IssueDescription', 'IssueUsers', 'IssueAdmins'],
  currentStep: 0,
  currencies: [] as Currency[],
  errors: [] as any[],
  isLoading: false as boolean,
  originatedIssues: [] as Issue[],
  participatedIssues: [] as Issue[],
  adminIssues: [] as Issue[],
};

export type IssuesState = typeof state;
