import axios, { Axios, AxiosResponse } from 'axios';
import { User, Issue } from '@/types/Issue';
import { Proposal } from '@/types/Proposals';

class Api {
  private client: Axios;

  constructor() {
    this.client = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: true,
    });
  }

  public getNonce = async (address: string): Promise<AxiosResponse> => {
    const { data } = await this.client.get(`/auth/nonce/${address}`);
    return data;
  }

  public verifyNonce = async (signature: string, address: string): Promise<User> => {
    const { data: { user } } = await this.client.post('/auth/nonce/verify', { signature, address });
    return user as User;
  }

  public signUp = async (walletAddress: string): Promise<User> => {
    const { data } = await this.client.post('/auth/signup', { walletAddress });
    return data as User;
  }

  public logout = async (): Promise<AxiosResponse> => {
    const { data } = await this.client.post('/auth/logout');
    return data;
  }

  public createIssue = async (issue: Issue): Promise<AxiosResponse> => await this.client.post('/issues', issue);

  public fetchOriginatedIssues = async (_id: string): Promise<AxiosResponse> => {
    const { data } = await this.client.get(`/issues?originatorId=${_id}`);
    return data;
  }

  public fetchParticipatedIssues = async (workEmail: string): Promise<AxiosResponse> => {
    const { data } = await this.client.get(`/issues?workEmail=${workEmail}`);
    return data;
  }

  public fetchAdminIssues = async (adminAddress: string): Promise<AxiosResponse> => {
    const { data } = await this.client.get(`/issues?adminAddress=${adminAddress}`);
    return data;
  }

  public updateProfle = async (user: User): Promise<AxiosResponse> => {
    const { data } = await this.client.patch('/auth/profile', user);
    return data;
  }

  public fetchMe = async (): Promise<AxiosResponse> => {
    const { data } = await this.client.get('/auth/me');
    return data;
  }

  public participate = async (token: string): Promise<AxiosResponse> => {
    const { data } = await this.client.post('/issues/participate', { token });
    return data;
  }

  public fetchAddress = async (issueId: string): Promise<string> => {
    const { data: { address } } = await this.client.get(`/issues/get-address/${issueId}`);
    return address;
  }

  public fetchProposals = async (issueId: string): Promise<Proposal[]> => {
    const { data: { proposals } } = await this.client.get(`/proposals?issueId=${issueId}`);
    return proposals;
  }

  public createRemovalProposal = async (userId: string, issueId: string, position: string): Promise<AxiosResponse> => {
    const { data } = await this.client.post('/proposals', { userId, issueId, position });
    return data;
  }

  public fetchUserByAddress = async (userAddress: string): Promise<User | null> => {
    const { data } = await this.client.get(`/users?walletAddress=${userAddress}`);
    return data.data.length > 0 ? data.data[0] : null;
  }

  public fetchParticipants = async (issueId: string): Promise<any> => {
    const { data: { participants } } = await this.client.get(`/issues/participants/${issueId}`);
    return participants;
  }

  public sendVote = async (proposalId: string, vote: string): Promise<any> => {
    const { data } = await this.client.post('/proposals/vote', { proposalId, vote });
    return data;
  }

  public inviteAdmin = async (email: string, issueId: string): Promise<any> => {
    const { data } = await this.client.post('/issues/admins', { email, issueId });
    return data;
  }

  public removeAdmin = async (email: string, issueId: string): Promise<any> => {
    const { data } = await this.client.post('/issues/admins/remove', { email, issueId });
    return data;
  }
}

export default Api;
