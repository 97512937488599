import { GetterTree } from 'vuex';
import { WalletState } from './state';

export type Getters = {
  getBalance(state: WalletState): number;
  getAddress(state: WalletState): string;
  getIsConnected(state: WalletState): boolean;
}

export const getters: GetterTree<WalletState, WalletState> & Getters = {
  getBalance: (state) => state.balance,
  getAddress: (state) => state.address,
  getIsConnected: (state) => state.isConnected,
};
