import { computed } from 'vue';
import Swal from 'sweetalert2';
import { BigNumber, ethers } from 'ethers';
import Store from '@/store';
import { ActionTypes } from '@/store/modules/wallet/actions';
import {
  Currency,
  Option,
  User,
  Issue,
} from '@/types/Issue';
import Web3Service from '@/services/web3';

const { VUE_APP_STABLE_COIN: STABLE_COIN_ADDRESS } = process.env;

const useWeb3 = (store: typeof Store, web3service: any): Record<string, any> => {
  // const web3service: any = new Web3Service();

  const address = computed(() => store.getters['Wallet/getAddress']);
  const balance = computed(() => store.getters['Wallet/getBalance']);
  const isConnected = computed(() => store.getters['Wallet/getIsConnected']);

  const refreshState = async () => {
    const isConnected = await store.dispatch(`Wallet/${ActionTypes.FETCH_IS_CONNECTED}`, web3service);
    if (!isConnected) return;
    await store.dispatch(`Wallet/${ActionTypes.FETCH_ADDRESS}`, web3service);
    await store.dispatch(`Wallet/${ActionTypes.FETCH_BALANCE}`, web3service);
  };

  const singMessage = async (message: string): Promise<string> => await web3service.signMessage(message);

  const connect = async () => {
    await web3service.connect();
    await refreshState();
  };

  const disconnect = async () => {
    await web3service.disconnect();
    // location.reload();
  };

  const toWei = (n: number): ethers.BigNumberish => {
    const number = +(n * 10000).toFixed(0);
    const wei = ethers.BigNumber.from('100000000000000');
    return ethers.BigNumber.from(number.toString()).mul(wei);
  };

  const parseParticipants = (issue: Issue): Array<any> => {
    const participants = issue.users.map((user: User): Array<any> | null => {
      if (!user.walletAddress) return null;
      return [
        user.walletAddress,
        user.options.map((option: Option) => option.currencyAddress),
        user.options.map((option: Option) => option.percentage * 100),
      ];
    });
    return participants.filter((p) => p !== null);
  };

  const deployIssue = async (issue: Issue): Promise<string> => {
    const maturityTimestamp = Math.floor(new Date(issue.maturityDate).getTime() / 1000);
    const exerciseTimestamp = Math.floor(new Date(issue.exerciseDate).getTime() / 1000);
    const currencies = issue.currencies.map((curr) => curr.address);
    const optionPrices = issue.currencies.map((curr) => {
      if (curr.optionPrice) {
        return curr.optionPrice.toString();
      }
      return 0;
    });

    const hasAdmins = issue.admins.some((a) => a.walletAddress !== address.value);

    return await web3service.deployIssue(
      exerciseTimestamp,
      maturityTimestamp,
      currencies,
      optionPrices,
      issue._id,
      hasAdmins,
    );
  };

  const addParticipants = async (issue: Issue, address: string) => {
    const participants = parseParticipants(issue);
    if (participants.length === 0) throw new Error('No participants');
    return await web3service.addParticipants(participants, address);
  };

  const hasParticipants = async (address: string): Promise<boolean> => await web3service.hasParticipants(address);

  const depositFunds = async (amounts: {[key: string]: number}, vaultAddress: string) => {
    const addresses = Object.keys(amounts);
    for (let i = 0; i < addresses.length; i++) {
      const tokenAddress = addresses[i];
      const amount = toWei(amounts[tokenAddress]);
      await web3service.approve(tokenAddress, vaultAddress, amount);
      await web3service.depositFunds(tokenAddress, amount, vaultAddress);
    }
    return '';
  };

  const getEligibleAmount = async (
    vaultAddress: string,
    token: string,
    participant: string,
  ) => {
    const weiAmount = await web3service.getEligibleAmount(vaultAddress, token, participant);
    return ethers.utils.formatEther(weiAmount).toString();
  };

  const getEligibleAmountAtTimestamp = async (
    vaultAddress: string,
    token: string,
    participant: string,
    ts: number = Math.floor(+new Date() / 1000),
  ) => {
    const weiAmount = await web3service.getEligibleAmountAtTimestamp(vaultAddress, token, participant, ts);
    return ethers.utils.formatEther(weiAmount).toString();
  };

  const claimSuccesfullMessage = async () => {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'You claimed your tokens successfully',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const claimFailedMessage = async () => {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'You are not eligible for tokens',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const listenOnIssueEvents = async (vaultAddress: string) => {
    Web3Service.listenOnClaim(vaultAddress, claimSuccesfullMessage, claimFailedMessage);
  };

  const calculatePrice = async (issue: Issue, token: string, userAddress: string) => {
    const balance = await web3service.balanceOf(issue.address, token);
    const user = issue.users.filter((user: User) => user.walletAddress.toLowerCase() === userAddress.toLowerCase());
    const currencies = issue.currencies.filter((currency) => currency.address.toLowerCase() === token.toLowerCase());
    const options = user[0].options.filter((option) => option.currencyAddress.toLowerCase() === token.toLowerCase());
    const { optionPrice } = currencies[0];
    const { percentage } = options[0];
    const amount = balance * (percentage / 100) * (optionPrice || 1);
    return amount;
  };

  const calculatePriceBN = async (issue: Issue, token: string, userAddress: string, amount: number) => {
    // const balance = await web3service.balanceOf(issue.address, token);
    // const user = issue.users.filter((user: User) => user.walletAddress.toLowerCase() === userAddress.toLowerCase());
    const currencies = issue.currencies.filter((currency) => currency.address.toLowerCase() === token.toLowerCase());
    // const options = user[0].options.filter((option) => option.currencyAddress.toLowerCase() === token.toLowerCase());
    const { optionPrice } = currencies[0];
    // console.log(optionPrice);
    // const optionPriceBN = ethers.utils.parseUnits((optionPrice || 1).toString());
    const amountBN = ethers.utils.parseUnits(((optionPrice || 1) * amount).toString());
    return amountBN;
    // return optionPriceBN.mul(amountBN);
    // const { percentage } = options[0];
    // const balanceBN = ethers.utils.parseUnits(balance.toString());
    // const percentageBN = ethers.utils.parseUnits((percentage / 100).toString());
    // const optionPriceBN = ethers.utils.parseUnits((optionPrice || 1).toString());
    // // const amount = balance * (percentage / 100) * (optionPrice || 1);
    // const amount = balanceBN
    //   .mul(percentageBN)
    //   .mul(optionPriceBN)
    // // normalize because it is multiplied twice with magnitude of 10**18
    //   .div(ethers.BigNumber.from((10 ** 18).toString()))
    //   .div(ethers.BigNumber.from((10 ** 18).toString()));
    // return amount;
  };

  const claim = async (issue: Issue, token: string, userAddress: string, amount: number) => {
    const price = await calculatePriceBN(issue, token, userAddress, amount);
    await web3service.approve(STABLE_COIN_ADDRESS, issue.address, price);
    await web3service.claim(issue.address, token, amount);
  };

  const withdraw = async (vaultAddress: string, token: string) => {
    try {
      await web3service.withdraw(vaultAddress, token);
    } catch (error) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Transaction failed',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const checkNetwork = async () => {
    const isValid = await web3service.isValidNetwork();
    if (!isValid) await web3service.switchToSupportedNetwork();
  };

  const isRemovedFromIssue = async (v: string, a: string) => await web3service.isRemoved(v, a);

  const isClaimed = async (v: string, a: string, t: string) => await web3service.isClaimed(v, a, t);

  const balanceOf = async (issue: string, currency: string) => await web3service.balanceOf(issue, currency);

  const reAdd = async (issue: string, address: string) => await web3service.reAddEmployee(issue, address);

  const removeAsAdmin = async (issue: string, address: string) => await web3service.remove(issue, address);

  return {
    reAdd,
    claim,
    address,
    balance,
    connect,
    withdraw,
    isClaimed,
    balanceOf,
    disconnect,
    deployIssue,
    singMessage,
    isConnected,
    refreshState,
    checkNetwork,
    depositFunds,
    removeAsAdmin,
    calculatePrice,
    addParticipants,
    hasParticipants,
    getEligibleAmount,
    isRemovedFromIssue,
    listenOnIssueEvents,
    getEligibleAmountAtTimestamp,
  };
};

export default useWeb3;
