import { ethers } from 'ethers';
import { ActionContext, ActionTree } from 'vuex';
import { WalletMutationTypes } from './mutations';
import { WalletState } from './state';

export enum ActionTypes {
  FETCH_ADDRESS = 'FETCH_ADDRESS',
  FETCH_BALANCE = 'FETCH_BALANCE',
  FETCH_IS_CONNECTED = 'FETCH_IS_CONNECTED',
}

export interface Actions {
  [ActionTypes.FETCH_ADDRESS](
    context: ActionContext<WalletState, WalletState>,
    web3Service: any,
  ): void;
  [ActionTypes.FETCH_BALANCE](
    context: ActionContext<WalletState, WalletState>,
    web3Service: any,
  ): void;
  [ActionTypes.FETCH_IS_CONNECTED](
    context: ActionContext<WalletState, WalletState>,
    web3Service: any,
  ): void;
}

export const actions: ActionTree<WalletState, WalletState> & Actions = {
  /**
   * Fetch wallet address from metamask
   * @param context ActionContext
   */
  async [ActionTypes.FETCH_ADDRESS](context: ActionContext<WalletState, WalletState>, web3Service) {
    const address = await web3Service.getAddress();
    context.commit(WalletMutationTypes.SET_ADDRESS, address);
  },
  /**
   * Fetch wallet balance from metamask
   * @param context ActionContext
   */
  async [ActionTypes.FETCH_BALANCE](context: ActionContext<WalletState, WalletState>, web3Service) {
    const address = await web3Service.getAddress();
    const b = await web3Service.getBalance(address);
    const balance = ethers.utils.formatEther(b).substr(0, 7);
    context.commit(WalletMutationTypes.SET_BALANCE, balance);
  },
  /**
   * Fetch wallet connection status
   * @param context ActionContext
   */
  async [ActionTypes.FETCH_IS_CONNECTED](context: ActionContext<WalletState, WalletState>, web3Service) {
    const isConnected = await web3Service.isConnected();
    context.commit(WalletMutationTypes.SET_IS_CONNECTED, isConnected);
    return isConnected;
  },
};
