import { createStore } from 'vuex';
import Auth from './modules/auth';
import Issues from './modules/issues';
import Wallet from './modules/wallet';

export default createStore({
  modules: {
    Auth,
    Issues,
    Wallet,
  },
});
