
import * as yup from 'yup';
import Swal from 'sweetalert2';
import { useStore } from 'vuex';
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';
import useIssues from '@/composables/useIssues';

export default defineComponent({
  props: ['issue'],
  emits: ['reloadIssue'],

  setup(props, context) {
    const { errorMessage, value } = useField(
      'email',
      yup.string().required().email().trim(),
    );
    const store = useStore();
    const { inviteAdmin, createRemovalProposal, removeAdminApi } = useIssues(store);

    const invite = async () => {
      if (!errorMessage.value) {
        const { message } = await inviteAdmin(value.value, props.issue._id);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 1500,
        });
        context.emit('reloadIssue');
      }
    };

    const remove = async (userId: string, userEmail: string) => {
      let data;
      if (!userId) {
        data = await removeAdminApi(userEmail, props.issue._id);
      } else {
        data = await createRemovalProposal(userId, props.issue._id, 'admin');
      }
      console.log(data);
      const { message } = data;

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
      context.emit('reloadIssue');
      return null;
    };

    return {
      value,
      props,
      invite,
      remove,
      errorMessage,
    };
  },
});
