
import { useStore } from 'vuex';
import {
  onMounted,
  defineComponent,
  ref,
  Ref,
  inject,
} from 'vue';
import useIssues from '@/composables/useIssues';
import { Proposal } from '@/types/Proposals';
import useAuth from '@/composables/useAuth';
import RemainingTime from '@/components/RemainingTime.vue';

export default defineComponent({
  props: ['issueId'],
  components: {
    RemainingTime,
  },
  setup(props) {
    const store = useStore();
    const web3Service = inject('web3Service');
    const { user } = useAuth(store, web3Service);
    const { signVote, sendVote, fetchProposals } = useIssues(store);

    const proposals: Ref<Proposal> | Ref<any> = ref(null);

    onMounted(async () => {
      proposals.value = await fetchProposals(props.issueId);
    });

    const vote = async (proposalId: string, ballot: string) => {
      try {
        const signature = await signVote(ballot);
        await sendVote(proposalId, signature);
      } catch (error) {
        console.log(error);
      }
    };

    const findVote = (proposal: Proposal) => {
      const vote = proposal.votes.filter((vote) => vote.adminId === user.value._id);
      return vote[0].ballot;
    };

    const countVotes = (proposal: Proposal, ballot: string) => {
      const votes = proposal.votes.filter((vote) => vote.ballot.toLowerCase() === ballot.toLowerCase());
      return votes.length;
    };

    return {
      vote,
      findVote,
      proposals,
      countVotes,
    };
  },
});
